import React, { useContext } from 'react';
import '../styles/components/Footer.scss'
import { NavLink } from 'react-router-dom';
import { GlobalDataContext } from '../context/GlobalDataContext';

function Footer() {
  const { businessInfo } = useContext(GlobalDataContext);

  return (
    <div>
      <footer className='footer'>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {businessInfo && businessInfo.logoFooter && (
                <div className="logo-container">
                  <NavLink to="/">
                    <img className="logo" src={businessInfo.logoFooter} alt="Business Logo" />
                  </NavLink>
                </div>
              )}
              <div className='footer-info-row'>
                {businessInfo && businessInfo.hours && (
                  <span className='hours' dangerouslySetInnerHTML={{ __html: businessInfo.hours }} />
                )}
                {businessInfo && businessInfo.phone && (
                  <a className='phone' href={`tel:${businessInfo.phone}`}>H - {businessInfo.phone}</a>
                )}
                {businessInfo && businessInfo.cell_phone && (
                  <a href={`tel:${businessInfo.cell_phone}`}>C - {businessInfo.cell_phone}</a>
                )}
                {businessInfo?.social_media_1.link?.url && (
                  <a className='footer-social' href={businessInfo.social_media_1.link.url} target='_blank' rel="noopener noreferrer">
                    <i className={businessInfo.social_media_1.link.title}></i>
                  </a>
                )}
              </div>
              <div className=''>
                <ul className="nav primary-nav justify-content-center">
                  <li>
                    <NavLink to="/" end>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/projects">Projects</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services">Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/whoweare">Who We Are</NavLink>
                  </li>
                  <li>
                    <NavLink to="/estimate">Schedule an Estimate</NavLink>
                  </li>
                  <li>
                    <NavLink to="/reviewus">Leave a Review</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className='copyright'><span className='client-copy'>Content © lizotteconstruction.com</span> <span className='divider'>|</span> <span className='dev-copy'>Design & Development © <a target='_blank' href="https://checkmatewebdesign.com/">CheckmateWebDesign.com</a></span> </footer>
    </div>
  );
}

export default Footer;
