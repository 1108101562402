import React, { useContext, useState, useEffect, useRef } from 'react';
import { GlobalDataContext } from '../context/GlobalDataContext';
import { Link } from 'react-router-dom';
import '../styles/components/FooterHero.scss';

function FooterHero() {
  const { footerHero } = useContext(GlobalDataContext);

  const [scrollY, setScrollY] = useState(0);
  const anchorRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (anchorRef.current) {
        const anchorPosition = anchorRef.current.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;
        // Start moving background when the anchor enters the viewport
        setScrollY(Math.max(0, viewportHeight - anchorPosition));
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundStyle = {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundImage: "Lizotte-Construction-Trucking.webp')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `left ${(scrollY - 500) * -0.5}px`, // Moves upward on scroll
  };

  // If no footerHero data, show loading or fallback message
  if (!footerHero) {
    return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-7'>

          </div>
        </div>
      </div>
    ); // Or show a skeleton loader
  }

  return (
    <div>
      <div className="hero footer-hero" ref={anchorRef}>
        <div className="hero-object" style={backgroundStyle}></div>
        <div
          className='container container-arrows-down'
          data-aos="fade-down"
          data-aos-duration="400"
          data-aos-offset="0"
          data-aos-delay="150"
        >
          <img className='arrows-down' src='/arrows-down.webp' alt='Description of image' />
        </div>
        <div className="container">
          <div className="hero-content">
            {footerHero.line_1 && (
              <h2
                className="line-1"
                data-aos="slide-up"
                data-aos-duration="300"
                data-aos-delay="50"
                dangerouslySetInnerHTML={{ __html: footerHero.line_1 }}
              />
            )}
            {footerHero.line_2 && <p>{footerHero.line_2}</p>}
            {footerHero.line_3 && <p>{footerHero.line_3}</p>}
            {footerHero.line_4 && <p>{footerHero.line_4}</p>}

            <div className="hero-buttons">
              {/* Ensure button URLs are valid before rendering */}
              {footerHero?.hero_button_1?.url?.trim() && (
                <div
                  className="hero-buttons"
                  data-aos="slide-up"
                  data-aos-duration="300"
                  data-aos-delay="100"
                >
                  <Link to={footerHero.hero_button_1.url}>
                    {footerHero.hero_button_1.title}
                  </Link>
                </div>
              )}

              {footerHero?.hero_button_2?.url?.trim() && (
                <div
                  className="hero-buttons"
                  data-aos="slide-up"
                  data-aos-duration="300"
                  data-aos-delay="100"
                >
                  <Link to={footerHero.hero_button_2.url}>
                    {footerHero.hero_button_2.title}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterHero;
