import React, { useEffect, useState } from 'react';
import Hero from '../components/Hero';
import HeroSkeleton from '../components/HeroSkeleton'; // Import the HeroSkeleton component
import { fetchPageContent } from '../utils/api';
import useFetch from '../hooks/useFetch';
import '../styles/pages/Home.scss';
import '../styles/Global.scss';
import { Link } from 'react-router-dom';
import FeaturedProjects from '../components/ProjectsSlider';
import FooterHero from '../components/FooterHero';
import Footer from '../components/Footer';
import axios from 'axios';
import Testimonials from '../components/Testimonials';
import { Helmet } from 'react-helmet-async';
// import GoogleReviews from '../components/GoogleReviews';

const Home = () => {
  const { data: pageContent, loading } = useFetch(() => fetchPageContent('home'), 'home');
  const [imageUrls, setImageUrls] = useState({});
  // const placeId = "ChIJ6Z988yf5sUwRg9mqDvYcnxE"; // Lizotte Construction's Place ID


  useEffect(() => {
    const fetchAllImages = async () => {
      const icons = [
        { key: 'feature_1', id: pageContent?.whyChoose?.feature_1?.icon },
        { key: 'feature_2', id: pageContent?.whyChoose?.feature_2?.icon },
        { key: 'feature_3', id: pageContent?.whyChoose?.feature_3?.icon },
      ];

      const services = [
        { key: 'service_1', id: pageContent?.homeServices?.service_1?.image },
        { key: 'service_2', id: pageContent?.homeServices?.service_2?.image },
        { key: 'service_3', id: pageContent?.homeServices?.service_3?.image },
        { key: 'service_4', id: pageContent?.homeServices?.service_4?.image },
        { key: 'service_5', id: pageContent?.homeServices?.service_5?.image },
      ];

      const allImages = [...icons, ...services];
      const urls = {};

      for (let image of allImages) {
        if (image.id) {
          try {
            // const response = await axios.get(`http://localhost:10029/wp-json/wp/v2/media/${image.id}`, { credentials: 'include' });
            const response = await axios.get(`${process.env.REACT_APP_WORDPRESS_MEDIA_BASE}/${image.id}`, { credentials: 'include' });
            urls[image.key] = response.data.source_url; // Set each image URL by key
          } catch (error) {
            console.error(`Error fetching image for ${image.key}:`, error.response?.data || error.message || error);
          }
        }
      }

      setImageUrls((prevUrls) => ({ ...prevUrls, ...urls })); // Merge with existing URLs
    };

    if (pageContent?.whyChoose || pageContent?.homeServices) {
      fetchAllImages();
    }
  }, [pageContent]);


  return (
    <div>
      {loading ? (
        // Render HeroSkeleton component when loading
        <div>
          <Helmet>
            <title>Home - Lizotte Construction</title>
            <meta name="description" content="At Lizotte Construction, we specialize in driveway maintenance and related services, ensuring your paths, driveways, and parking areas remain functional and visually appealing for years to come." />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="canonical" href="http://yourwebsite.com/home" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"></link>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
          </Helmet>
        </div>
      ) : (
        pageContent && (
          <>
            <Hero content={pageContent.hero} featuredMedia={pageContent.featuredMedia} />
            {pageContent.whyChoose && (
              <section className="why-choose panel">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="why-choose-title">
                        <h2 className="line-1"
                          data-aos="fade-right"
                          data-aos-duration="200"
                          dangerouslySetInnerHTML={{ __html: pageContent.whyChoose.title.line_1 }} />
                        <h2 className="line-2"
                          data-aos="fade-right"
                          data-aos-duration="350"
                          data-aos-delay="100"
                          dangerouslySetInnerHTML={{ __html: pageContent.whyChoose.title.line_2 }} />
                      </div>
                    </div>
                    <div className="col-md-6 col-why-choose-text" data-aos="fade-left" data-aos-duration="300" data-aos-delay="100">
                      <p className="why-choose-text" dangerouslySetInnerHTML={{ __html: pageContent.whyChoose.main_why_choose_text }} />
                      {pageContent.whyChoose.why_choose_button?.url && (
                        <Link to={pageContent.whyChoose.why_choose_button.url}>
                          {pageContent.whyChoose.why_choose_button.title}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="row features-row">
                    {['feature_1', 'feature_2', 'feature_3'].map((feature, index) => (
                      <div className="col-md-4" key={feature}
                        data-aos="fade-right"
                        data-aos-duration="350"
                        data-aos-delay={index * 100}
                      >
                        <div className="feature">
                          {imageUrls[feature] && (
                            <img src={imageUrls[feature]} alt={pageContent.whyChoose[feature]?.title || "Feature icon"} className="feature-icon" />
                          )}
                          {pageContent.whyChoose[feature]?.title && (
                            <h2>{pageContent.whyChoose[feature].title}</h2>
                          )}
                          {pageContent.whyChoose[feature]?.text && (
                            <p className="feature-text" >{pageContent.whyChoose[feature].text}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
            <FeaturedProjects />
            <section className="home-services">
              <div className="container">
                <div className="row">
                  {pageContent?.homeServices && Object.keys(pageContent.homeServices).map((serviceKey, idx) => {
                    const service = pageContent.homeServices[serviceKey];
                    return (
                      <div className="col-12 service-item" key={idx}>
                        <div className="row">
                          <div className="col-lg-6 col-service-image"
                            data-aos={idx % 2 === 0 ? "fade-left" : "fade-right"}
                            data-aos-duration="450"
                            data-aos-delay={20 * idx}
                          >
                            {imageUrls[serviceKey] && (
                              <img
                                // style={{ width: "100px" }}
                                src={imageUrls[serviceKey]}
                                alt="Service Image"
                                className="service-icon ratio 4x3"
                              />
                            )}
                          </div>
                          <div className="col-lg-6 col-service-content">
                            <div className="card-service-content"
                            >
                              {service.title && <h3 className='service-title'
                                data-aos={idx % 2 === 0 ? "fade-right" : "fade-left"}
                                data-aos-duration="350"
                                data-aos-delay={100 * idx}
                              >{service.title}</h3>}
                              {service.text && <p
                                data-aos="fade-up"
                                data-aos-duration="350"
                                data-aos-delay={100 * idx}
                              >{service.text}</p>}
                              {service.button && service.button.url && (
                                <Link to={service.button.url}
                                  className="service-button"
                                  data-aos="fade-up"
                                  data-aos-duration="350"
                                  data-aos-delay={50 * idx}>
                                  {service.button.title || "Learn More"}
                                </Link>
                              )}
                            </div>
                          </div>

                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>


            {/* <h2>Our Reviews</h2>
            <GoogleReviews placeId={placeId} /> */}

            <Testimonials />
            <FooterHero />
            <Footer />
          </>
        )
      )}
    </div >
  );
};

export default Home;
