import '../styles/components/Hero.scss';
import '../styles/Variables.scss';
import '../styles/Mixins.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalDataContext } from '../context/GlobalDataContext';
import HeroSkeleton from './HeroSkeleton';
import InteriorHeroSkeleton from './InteriorHeroSkeleton';

const Hero = ({ className, content, featuredMedia, variant = 'home' }) => {
  const { businessInfo } = useContext(GlobalDataContext);
  const [isLoading, setIsLoading] = useState(true);

  // Preload the featured image as soon as possible
  useEffect(() => {
    if (featuredMedia?.source_url) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = featuredMedia.source_url;
      document.head.appendChild(link);

      // Clean up preload link
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [featuredMedia?.source_url]); // Dependency array to ensure preload happens whenever the source changes

  // Wait until content and featuredMedia are available to stop loading state
  useEffect(() => {
    if (content) {
      setIsLoading(false);
    }
  }, [content, featuredMedia]);

  const isContentEmpty = !content?.line_1 && !content?.line_2 && !content?.line_3 && !content?.line_4;

  if (isLoading) {
    return variant === 'interior' ? <InteriorHeroSkeleton /> : <HeroSkeleton />;
  }

  if (isContentEmpty) {
    return null; // Don't render anything if all lines are empty
  }

  return (
    <div className={`hero ${className}`}>
      {featuredMedia && (
        <img
          className='hero-image'
          srcSet={`
            ${featuredMedia.source_url}?w=600 600w,
            ${featuredMedia.source_url}?w=1200 1200w,
            ${featuredMedia.source_url}?w=1800 1800w
          `}
          sizes="(max-width: 600px) 600px,
                 (max-width: 1200px) 1200px,
                 1800px"
          src={featuredMedia.source_url}
          alt={featuredMedia.alt_text}
          style={{
            objectPosition: `${content.settings.x_position}% ${content.settings.y_position}%`
          }}
          loading="eager"
          fetchpriority="high"
        />
      )}

      <div className='container'>
        <div className='hero-content'>
          {content?.line_1 && (
            <h2
              className='line-1'
              data-aos="fade-left"
              data-aos-duration="250"
              data-aos-delay="50"
              data-aos-easing="linear"
            >
              {content.line_1}
            </h2>
          )}
          {content?.line_2 && (
            <h2
              className='line-2 outline-text'
              data-aos="fade-left"
              data-aos-duration="250"
              data-aos-delay="50"
              data-aos-easing="linear"
            >
              {content.line_2}
            </h2>
          )}
          {content?.line_3 && (
            <h2
              className='line-3'
              data-aos="fade-left"
              data-aos-duration="250"
              data-aos-delay="50"
              data-aos-easing="linear"
            >
              {content.line_3}
            </h2>
          )}
          {content?.line_4 && (
            <h2
              className='line-4 outline-text'
              data-aos="fade-left"
              data-aos-duration="250"
              data-aos-delay="50"
              data-aos-easing="linear"
            >
              {content.line_4}
            </h2>
          )}

          {content?.hero_button_1?.url && content.hero_button_1.url.trim() !== "" && (
            <div
              id="strapline-anchor"
              className='hero-buttons'
              data-aos="fade-left"
              data-aos-duration="350"
              data-aos-delay="50"
            >
              {content?.hero_button_1?.url && content.hero_button_1.url.trim() !== "" && (
                <Link to={content.hero_button_1.url}>
                  {content.hero_button_1.title}
                </Link>
              )}
              {content?.hero_button_2?.url && content.hero_button_2.url.trim() !== "" && (
                <Link to={content.hero_button_2.url}>
                  {content.hero_button_2.title}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>

      {content?.strapline && (
        <div
          className='strapline'
          data-aos="fade"
          data-aos-duration="350"
          data-aos-delay="50"
          data-aos-anchor="#strapline-anchor"
        >
          <div className='container'>
            <p dangerouslySetInnerHTML={{ __html: content.strapline }} />
          </div>
        </div>
      )}

      <div
        className='container container-arrows-down'
        data-aos="fade-down"
        data-aos-duration="400"
        data-aos-offset="0"
        data-aos-delay="150"
        data-aos-anchor="#strapline-anchor"
      >
        <img className='arrows-down' src='/arrows-down.webp' alt='Description of image' />
      </div>

      <div
        className='social-media-container'
        data-aos="fade-down"
        data-aos-duration="100"
        data-aos-delay="100"
        data-aos-easing="linear"
      >
        {businessInfo?.social_media_1.link?.url && (
          <a href={businessInfo.social_media_1.link.url} target='_blank' rel="noopener noreferrer">
            <i className={businessInfo.social_media_1.link.title}></i>
            <span className='social-link-text'>{businessInfo.social_media_1.text}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default Hero;
