// src/components/Breadcrumbs.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const pageTitleMapping = {
  '/services/gravel': 'Gravel',
  '/services/septic-systems': 'Septic Systems',
  '/services/house-lots': 'House Lots',
  '/services/maintenance-and-more': 'Maintenance And More',
  '/services/garage-slabs': 'Garage Slabs',
};

function Breadcrumbs() {
  const location = useLocation();
  const path = location.pathname;

  const getPageTitle = () => pageTitleMapping[path] || 'Unknown Page'; // Fallback title

  return (
    <nav className="breadcrumbs mb-3">
      <NavLink to="/">Home</NavLink> &gt;
      <NavLink to="/services">Services</NavLink> &gt; <span>{getPageTitle()}</span>
    </nav>
  );
}

export default Breadcrumbs;
