import React, { useEffect, useState } from 'react';
import { fetchPageContent, fetchProjects } from '../utils/api';
import useFetch from '../hooks/useFetch';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import '../styles/components/ProjectsSlider.scss';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Download from "yet-another-react-lightbox/plugins/download";
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { Link } from 'react-router-dom';

const ProjectsSlider = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: pageContent } = useFetch(() => fetchPageContent('home'), 'home');
  const [showNavigation, setShowNavigation] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to extract images from the content
  const extractImagesFromContent = (content) => {
    const imageElements = [];
    const div = document.createElement('div');
    div.innerHTML = content;
    const imageTags = div.querySelectorAll('img');
    imageTags.forEach((img) => {
      imageElements.push({
        src: img.src,
        thumbnail: img.src,
        alt: img.alt
      });
    });
    return imageElements;
  };

  // Function to remove image tags from content
  const removeImagesFromContent = (content) => {
    return content.replace(/<img[^>]*>/g, '');
  };

  useEffect(() => {
    const getProjects = async () => {
      try {
        const data = await fetchProjects();
        const updatedProjects = data.map((project) => {
          if (project.content) {
            const images = extractImagesFromContent(project.content);
            const contentWithoutImages = removeImagesFromContent(project.content);
            return { ...project, images, contentWithoutImages };
          }
          return project;
        });

        setProjects(updatedProjects);
        setShowNavigation(data.length > 3);
        setLoading(false);
      } catch (error) {
        console.error("Error loading projects:", error);
        setLoading(false);
      }
    };

    getProjects();
  }, []);

  const openLightbox = (project, index) => {
    setCurrentProject(project);
    setCurrentSlide(index);
    setIsLightboxOpen(true);
  };

  if (loading)
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );

  const hasHomeProjectsCarousel = pageContent && pageContent.homeProjectsCarousel;

  return (
    <div className="featured-projects panel">
      <div className="container">
        <div className="featured-projects-why-choose-title">
          {hasHomeProjectsCarousel?.line_1 && (
            <h2
              className="line-1"
              data-aos="fade-up"
              data-aos-duration="350"
              dangerouslySetInnerHTML={{ __html: hasHomeProjectsCarousel.line_1 }}
            />
          )}
          {hasHomeProjectsCarousel?.line_2 && (
            <h2
              className="line-2"
              data-aos="fade-up"
              data-aos-duration="350"
              data-aos-delay="50"
              dangerouslySetInnerHTML={{ __html: hasHomeProjectsCarousel.line_2 }}
            />
          )}
        </div>

        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          modules={[Navigation]}
          loop={true}
          breakpoints={{
            768: { slidesPerView: 2, spaceBetween: 30 },
            992: { slidesPerView: 3 },
          }}
          onInit={(swiper) => {
            if (!showNavigation) {
              document.querySelector('.swiper-button-prev').style.display = 'none';
              document.querySelector('.swiper-button-next').style.display = 'none';
            }
          }}
        >
          {projects.map((project) => (
            <SwiperSlide key={project.id}>
              <div
                className="project-card"
                data-aos="fade-up"
                data-aos-duration="400"
              >
                {project.featuredMedia?.source_url && (
                  <a
                    className="featured-project-image-link"
                    onClick={(e) => {
                      e.preventDefault();
                      openLightbox(project, 0);
                    }}
                  >
                    <img
                      className="featured-project-image"
                      src={project.featuredMedia.source_url}
                      alt={project.title.rendered || "Featured Project"}
                    />
                    <i className="fa-solid fa-images"></i>
                    <span className="image-count">
                      {project.images && project.images.length > 0 ? `${project.images.length + 1}+` : `1+`}
                    </span>
                  </a>
                )}
                <h2
                  className="project-name"
                  dangerouslySetInnerHTML={{ __html: project.title }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          className={`custom-navigation ${showNavigation ? '' : 'hidden'}`}
          data-aos="fade-up"
          data-aos-duration="350"
        >
          <button className="swiper-button-prev" aria-label="Previous slide">
            <i className="fa-solid fa-circle-arrow-left"></i>
          </button>
          <button className="swiper-button-next" aria-label="Next slide">
            <i className="fa-solid fa-circle-arrow-right"></i>
          </button>
        </div>

        {hasHomeProjectsCarousel?.button?.url && (
          <div className="featured-projects-button">
            <Link className="button" to={hasHomeProjectsCarousel.button.url}>
              {hasHomeProjectsCarousel.button.title}
            </Link>
          </div>
        )}

        {isLightboxOpen && currentProject && (
          <Lightbox
            slides={[
              { src: currentProject.featuredMedia.source_url, thumbnail: currentProject.featuredMedia.source_url, alt: currentProject.title.rendered || "Featured Project" },
              ...(currentProject.images || [])
            ]}
            open={true}
            index={currentSlide}
            close={() => setIsLightboxOpen(false)}
            plugins={[Thumbnails, Download]}
            on={{
              view: ({ index }) => setCurrentSlide(index)
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectsSlider;