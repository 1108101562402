// src/utils/AOSInit.js

import AOS from 'aos';
import 'aos/dist/aos.css';

const initAOS = () => {
  AOS.init({
    duration: 1000, // Duration of animations (optional)
    once: true, // Whether animation should happen only once (optional)
  });
};

export default initAOS;
