import React, { useEffect, useState } from 'react';
import Hero from '../components/Hero';
import { fetchPageContent, fetchProjects } from '../utils/api';
import useFetch from '../hooks/useFetch';
import { Helmet } from 'react-helmet-async';
import InteriorHeroSkeleton from '../components/InteriorHeroSkeleton';
import FooterHero from '../components/FooterHero';
import Footer from '../components/Footer';
import Testimonials from '../components/Testimonials';
import '../styles/components/WordPressBlocks.scss';
import '../styles/components/testimonials.scss';
import '../styles/pages/Projects.scss';
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Download from 'yet-another-react-lightbox/plugins/download';
import Captions from "yet-another-react-lightbox/plugins/captions";
import 'yet-another-react-lightbox/plugins/captions.css'; // Add this for captions styling
import BeforeAfterImageSlider from '../components/BeforeAfterImageSlider';

const ProjectCard = ({ project }) => {
  const projectId = `project-${project.id}`;
  const [images, setImages] = useState([]);
  const [contentWithoutImages, setContentWithoutImages] = useState('');
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to extract images from the content
  const extractImagesFromContent = (content) => {
    const imageElements = [];
    const div = document.createElement('div');
    div.innerHTML = content;
    const imageTags = div.querySelectorAll('img');
    imageTags.forEach((img) => {
      imageElements.push({
        src: img.src,
        alt: img.alt,
        description: img.alt // Use alt as description for captions
      });
    });
    return imageElements;
  };

  // Function to remove image tags from content
  const removeImagesFromContent = (content) => {
    return content.replace(/<img[^>]*>/g, '');  // Removes all <img> tags
  };

  useEffect(() => {
    console.log("project.featuredMedia:", project.featuredMedia);

    if (project.content) {
      const extractedImages = extractImagesFromContent(project.content);
      setImages(extractedImages);
      const contentWithoutImgs = removeImagesFromContent(project.content);
      setContentWithoutImages(contentWithoutImgs);
    }
  }, [project.content]);

  const openLightbox = (index) => {
    setCurrentSlide(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="col-lg-6 col-xl-4 col-project">
      <div className="project-card">
        {project.featuredMedia?.source_url && (
          <a
            className="project-image-link"
            onClick={(e) => { e.preventDefault(); openLightbox(0); }}
          >
            <img
              className="project-image"
              src={project.featuredMedia.source_url}
              alt={project.featuredMedia.alt || "Featured Project"}
            />
            <i className="fa-solid fa-images"></i>
            <span className='project-name' dangerouslySetInnerHTML={{ __html: project.title }} />
            {images.length > 0 ? (
              <span className='image-count'>{images.length + 1}+</span>
            ) : (
              <span className='image-count'>1+</span>
            )}
          </a>
        )}

        {isLightboxOpen && (
          <Lightbox
            slides={[
              {
                src: project.featuredMedia.source_url,
                alt: project.alt,
                description: project.featuredMedia.alt_text // Use alt for description
              },
              ...images
            ]}
            open={true}
            index={currentSlide}
            close={() => setIsLightboxOpen(false)}
            on={{
              view: ({ index }) => setCurrentSlide(index)
            }}
            plugins={[Thumbnails, Download, Captions]}
          />
        )}

        <div>
          <div dangerouslySetInnerHTML={{ __html: contentWithoutImages }} />
        </div>
      </div>
    </div >
  );
};

const Projects = ({ images }) => {
  const { data: heroContent, data: interiorPageContent } = useFetch(() => fetchPageContent('projects'), 'projects');
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const beforeAfterImages = [
    {
      before: 'https://headless.lizotteconstruction.com/wp-content/uploads/2024/11/IMG_3185-scaled-e1733710906141.webp',
      after: 'https://headless.lizotteconstruction.com/wp-content/uploads/2024/11/IMG_3165-scaled.webp',
    },
    // Add more image sets here if needed
  ];
  useEffect(() => {
    const getProjects = async () => {
      try {
        const data = await fetchProjects();
        setProjects(data);
        setLoading(false);
      } catch (error) {
        console.error("Error loading projects:", error);
        setLoading(false);
      }
    };

    getProjects();
  }, []);


  return (
    <div>
      {loading ? (
        <InteriorHeroSkeleton />
      ) : (
        <>
          <Helmet>
            {/* ... Helmet content ... */}
          </Helmet>
          {heroContent && <Hero className="interior-hero" content={heroContent.hero} featuredMedia={heroContent.featuredMedia} />}
          <div className="container container-projects-content py-5">
            {/* <h1>Before and After</h1>
            <BeforeAfterImageSlider /> */}

            {/* <div>
              {beforeAfterImages && beforeAfterImages.length > 0 ? (
                beforeAfterImages.map((image, index) => (
                  <div key={index}>
                    <img src={image.before} alt="Before" />
                    <img src={image.after} alt="After" />
                  </div>
                ))
              ) : (
                <p>No images available.</p>
              )}
            </div> */}

            {interiorPageContent?.interiorPageContent && (
              <div className="row justify-content-center main-page-content">
                <div className='col-12' dangerouslySetInnerHTML={{ __html: interiorPageContent.interiorPageContent }} />
              </div>
            )}
            <div className="projects panel pt-0">
              <div className="row">
                {projects.map((project) => (
                  <ProjectCard key={project.id} project={project} />
                ))}
              </div>
            </div>
          </div>
          <Testimonials />
          <FooterHero />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Projects;