import { NavLink, useLocation } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';
import { GlobalDataContext } from '../context/GlobalDataContext';
import '../styles/components/Header.scss';

function Header() {
  const { businessInfo } = useContext(GlobalDataContext);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollUpDistance, setScrollUpDistance] = useState(0);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [aosComplete, setAosComplete] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null); // Track active submenu for accordion behavior

  const scrollThreshold = 70;
  const scrollUpThreshold = 50;
  const location = useLocation();

  const isServicesActive = location.pathname.startsWith('/services');

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

  const maxTextLength = 16;

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;

      if (currentScroll <= scrollThreshold) {
        setShowHeader(true);
        setScrollUpDistance(0);
        setLastScrollTop(0);
        return;
      }

      if (currentScroll > lastScrollTop) {
        setShowHeader(false);
        setScrollUpDistance(0);
      } else {
        setScrollUpDistance((prev) => prev + (lastScrollTop - currentScroll));
        if (scrollUpDistance >= scrollUpThreshold) {
          setShowHeader(true);
          setScrollUpDistance(0);
        }
      }

      setLastScrollTop(currentScroll);
    };

    const handleClickOutside = (e) => {
      if (!e.target.closest('.has-submenu')) {
        setSubmenuOpen(false);
      }
    };

    const handleAosCompletion = () => {
      setTimeout(() => setAosComplete(true), 1000);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', handleClickOutside);
    window.addEventListener('load', handleAosCompletion);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('load', handleAosCompletion);
    };
  }, [lastScrollTop, scrollUpDistance]);

  useEffect(() => {
    // Update sidebar's top position based on `showHeader` and `visible` class
    const sidebar = document.querySelector('.sidebar');
    if (sidebar) {
      // sidebar.style.top = showHeader ? '170px' : '15px';
      // Remove previous classes if they exist
      sidebar.classList.remove('header-is-showing', 'header-is-closed');

      // Add the appropriate class based on showHeader state
      sidebar.classList.add(showHeader ? 'header-is-showing' : 'header-is-closed');
    }
  }, [showHeader]);

  const toggleSubmenu = (e) => {
    e.stopPropagation();
    setSubmenuOpen(!submenuOpen);
  };

  const [isSidenavOpen, setSidenavOpen] = useState(false);

  const toggleSidenav = () => {
    setSidenavOpen(!isSidenavOpen);
  };

  return (
    <header className={`banner ${showHeader ? 'visible' : 'hidden'}`}>
      <div className="container">
        <nav>
          {businessInfo && businessInfo.logo && (
            <div
              className="logo-container"
              data-aos="slide-down"
              data-aos-duration="200"
              data-aos-once="true"
            >
              <NavLink to="/">
                <img className="logo" src={businessInfo.logo} alt="Business Logo" />
              </NavLink>
            </div>
          )}
          <div className="w-100">
            <ul className="top-nav">
              <li className='d-lg-inline-block d-none'><NavLink to="/reviewus">Leave a Review</NavLink></li>
              <li className='d-lg-inline-block d-none'><NavLink to="/contact">Contact</NavLink></li>
              {businessInfo && businessInfo.phone && (
                <li><a href={`tel:${businessInfo.phone}`}>H - {businessInfo.phone}</a></li>
              )}
              {businessInfo && businessInfo.cell_phone && (
                <li className='d-md-inline-block d-none'><a href={`tel:${businessInfo.cell_phone}`}>C - {businessInfo.cell_phone}</a></li>
              )}
            </ul>
            <ul className={`primary-nav ${aosComplete ? 'aos-complete' : ''}`}>
              <li><NavLink to="/" end>Home</NavLink></li>
              <li><NavLink to="/projects">Projects</NavLink></li>
              <li
                className={`has-submenu ${submenuOpen ? 'open' : ''} ${isServicesActive ? 'active' : ''}`}
                onClick={toggleSubmenu}
              >
                <span className={`menu-item ${isServicesActive ? 'active' : ''}`}>
                  Services <i className="fa-solid fa-caret-down"></i>
                </span>
                <ul className="submenu"
                >
                  {[
                    { path: '/services', label: 'All Services' },
                    { path: '/services/gravel', label: 'Gravel' },
                    { path: '/services/septic-systems', label: 'Septic Systems' },
                    { path: '/services/house-lots', label: 'House Lots' },
                    { path: '/services/maintenance-and-more', label: 'Maintenance and More' },
                    { path: '/services/garage-slabs', label: 'Garage Slabs' },
                  ].map((link) => (
                    <li key={link.path}>
                      <NavLink
                        to={link.path}
                        onClick={() => setSubmenuOpen(false)}
                      >
                        {/* Conditionally render icons based on the link path */}
                        {link.path === '/services' ? <i className="fa-solid fa-gear"></i> : ''}
                        {link.path === '/services/gravel' && <img src='../charcoal-icon.webp' />}
                        {link.path === '/services/septic-systems' && <i className="fa fa-tint"></i>}
                        {link.path === '/services/house-lots' && <i className="fa fa-home"></i>}
                        {link.path === '/services/maintenance-and-more' && <i className="fa fa-wrench"></i>}
                        {link.path === '/services/garage-slabs' && <i class="fa-solid fa-warehouse"></i>}
                        {truncateText(link.label, maxTextLength)}
                      </NavLink>
                      {link.label.length > maxTextLength && (
                        <span
                          className="tooltip-icon"
                          onMouseEnter={() => setHoveredLink(link.label)}
                          onMouseLeave={() => setHoveredLink(null)}
                        >
                          <i className="fa fa-info-circle"></i>
                          {hoveredLink === link.label && (
                            <div className="custom-tooltip">
                              <NavLink onClick={() => setSubmenuOpen(false)} to={link.path}>{link.label}
                              </NavLink>
                            </div>
                          )}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              <li><NavLink to="/whoweare">Who We Are</NavLink></li>
              <li><NavLink className="button" to="/estimate">Schedule a Free Estimate</NavLink></li>
              {/* Hamburger Icon */}
              <div id="hamburger" onClick={toggleSidenav} className={`sidebar-nav ${isSidenavOpen ? 'open' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </ul>
          </div>
        </nav>
      </div>

      {isSidenavOpen && <div className="overlay" onClick={toggleSidenav}></div>}
      <div className={`sidenav ${isSidenavOpen ? 'open' : ''}`}>
        <ul className="nav-items">
          {businessInfo && businessInfo.logo && (
            <div
              className="logo-container"
              data-aos="slide-down"
              data-aos-duration="200"
              data-aos-once="true"
            >
              <NavLink to="/" className="sidenav-logo-link" onClick={() => setSidenavOpen(false)}>
                <img className="logo" src={businessInfo.logo} alt="Business Logo" />
              </NavLink>
            </div>
          )}
          <div className='hamburger-sidenav-close'>
            <div id="hamburger" onClick={toggleSidenav} className={`sidebar-nav ${isSidenavOpen ? 'open' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <li><NavLink to="/" onClick={() => setSidenavOpen(false)} end>Home</NavLink></li>
          <li><NavLink to="/projects" onClick={() => setSidenavOpen(false)}>Projects</NavLink></li>
          <li
            className={`has-submenu ${submenuOpen ? 'open' : ''} ${isServicesActive ? 'active' : ''}`}
            onClick={toggleSubmenu}
          >
            <span className={`menu-item ${isServicesActive ? 'active' : ''}`}>
              Services <i className="fa-solid fa-caret-down"></i>
            </span>
            <ul className="submenu">
              {[
                { path: '/services', label: 'All Services' },
                { path: '/services/gravel', label: 'Gravel' },
                { path: '/services/septic-systems', label: 'Septic Systems' },
                { path: '/services/house-lots', label: 'House Lots' },
                { path: '/services/maintenance-and-more', label: 'Maintenance and More' },
                { path: '/services/garage-slabs', label: 'Garage Slabs' },
              ].map((link) => (
                <li key={link.path}>
                  <NavLink
                    to={link.path}
                    onClick={() => setSidenavOpen(false)}
                  >
                    {/* Conditionally render icons based on the link path */}
                    {link.path === '/services' ? <i className="fa-solid fa-gear"></i> : ''}
                    {link.path === '/services/gravel' && <img src='../charcoal-icon.webp' />}
                    {link.path === '/services/septic-systems' && <i className="fa fa-tint"></i>}
                    {link.path === '/services/house-lots' && <i className="fa fa-home"></i>}
                    {link.path === '/services/maintenance-and-more' && <i className="fa fa-wrench"></i>}
                    {link.path === '/services/garage-slabs' && <i class="fa-solid fa-warehouse"></i>}
                    {truncateText(link.label, maxTextLength)}
                  </NavLink>
                  {link.label.length > maxTextLength && (
                    <span
                      className="tooltip-icon"
                      onMouseEnter={() => setHoveredLink(link.label)}
                      onMouseLeave={() => setHoveredLink(null)}
                    >
                      <i className="fa fa-info-circle"></i>
                      {hoveredLink === link.label && (
                        <div className="custom-tooltip">
                          <NavLink onClick={() => setSidenavOpen(false)} to={link.path}>{link.label}
                          </NavLink>
                        </div>
                      )}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </li>
          <li><NavLink to="/whoweare" onClick={() => setSidenavOpen(false)}>Who We Are</NavLink></li>
          <li><NavLink to="/reviewus" onClick={() => setSidenavOpen(false)}>Leave a Review</NavLink></li>
          <li><NavLink to="/contact" onClick={() => setSidenavOpen(false)}>Contact</NavLink></li>
          {businessInfo && businessInfo.phone && (
            <li><a href={`tel:${businessInfo.phone}`}>H - {businessInfo.phone}</a></li>
          )}
          {businessInfo && businessInfo.cell_phone && (
            <li><a href={`tel:${businessInfo.cell_phone}`}>C - {businessInfo.cell_phone}</a></li>
          )}
          <li className='li-sidenav-button' onClick={() => setSidenavOpen(false)}><NavLink className="button" to="/estimate">Schedule a Free Estimate</NavLink></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
