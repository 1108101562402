// src/context/GlobalDataContext.js

import React, { createContext, useEffect, useState } from 'react';
import { fetchGlobalData } from '../utils/api';

export const GlobalDataContext = createContext();

export const GlobalDataProvider = ({ children }) => {
  const [businessInfo, setBusinessInfo] = useState(null);
  const [footerHero, setFooterHero] = useState(null); // New state for footerHero
  const [testimonials, setTestimonials] = useState(null); // New state for footerHero

  useEffect(() => {
    const loadGlobalData = async () => {
      const data = await fetchGlobalData();
      if (data) {
        setBusinessInfo(data.businessInfo);
        setFooterHero(data.footerHero); // Set footerHero from fetched data
        setTestimonials(data.testimonials); // Set footerHero from fetched data
      }
    };

    loadGlobalData();
  }, []);

  return (
    <GlobalDataContext.Provider value={{ businessInfo, footerHero, testimonials }}> {/* Provide footerHero here */}
      {children}
    </GlobalDataContext.Provider>
  );
};
