import React, { useRef, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Input, Button, Modal, Checkbox } from 'antd';
import * as Yup from 'yup';
import '../styles/components/FreeEstimateFormScss.scss';
import emailjs from '@emailjs/browser';

// Define the validation schema using Yup
const validationSchema = Yup.object({
  user_name: Yup.string().required('Name is required'),
  user_email: Yup.string().email('Invalid email address').required('Email is required'),
  user_phone: Yup.string()
    // .matches(/^[0-9]+$/, 'Phone number must be digits')
    .required('Phone number is required'),
  message: Yup.string().required('Message is required'),
  services: Yup.array().min(1, 'At least one service is required'), // Validation for services
  zip_code: Yup.string()
    .matches(/^\d{5}$/, 'Invalid zip code')
    .required('Zip code is required'), // Validation for zip code
});

const FreeEstimateForm = ({ businessInfo }) => {
  const [isModalVisible, setIsModalVisible] = useState(false); // Success modal visibility state
  const [isError, setIsError] = useState(false); // Error modal visibility state
  const form = useRef();

  const handleModalClose = () => {
    setIsModalVisible(false); // Close the success modal
    setIsError(false); // Close the error modal
  };

  const sendEmail = (values, { resetForm }) => {
    emailjs
      .sendForm('service_7nlp1xf', 'template_z0pvqvi', form.current, {
        publicKey: '_7gzj3yWYRqYdxOZn',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsModalVisible(true); // Show success modal upon successful submission
          resetForm(); // Reset the form fields
        },
        (error) => {
          console.log('FAILED...', error.text);
          setIsError(true); // Show error modal if submission fails
        },
      );
  };

  return (
    <>
      <Formik
        initialValues={{
          user_name: '',
          user_email: '',
          user_phone: '',
          services: [],
          zip_code: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={sendEmail}
      >
        {({ errors, touched, isValid, dirty, values, handleChange }) => (
          <Form ref={form}>
            <div className='form-group'>
              <label>Name</label>
              <Field
                type="text"
                name="user_name"
                as={Input}
                className={errors.user_name && touched.user_name ? 'input-error' : ''}
              />
              {errors.user_name && touched.user_name && <div className="error">{errors.user_name}</div>}
            </div>

            <div className='form-group'>
              <label>Email</label>
              <Field
                type="email"
                name="user_email"
                as={Input}
                className={errors.user_email && touched.user_email ? 'input-error' : ''}
              />
              {errors.user_email && touched.user_email && <div className="error">{errors.user_email}</div>}
            </div>

            <div className='form-group'>
              <label>Phone</label>
              <Field
                type="phone"
                name="user_phone"
                as={Input}
                className={errors.user_phone && touched.user_phone ? 'input-error' : ''}
              />
              {errors.user_phone && touched.user_phone && <div className="error">{errors.user_phone}</div>}
            </div>

            {/* Services Checkbox Group */}
            <div className='form-group'>
              <label className='d-block w-100'>Services</label>
              <Checkbox.Group
                name="services"
                value={values.services}
                onChange={(checkedValues) =>
                  handleChange({ target: { name: 'services', value: checkedValues } })
                }
              >
                <Checkbox value="Gravel">Gravel</Checkbox>
                <Checkbox value="Septic">Septic</Checkbox>
                <Checkbox value="House Lots">House Lots</Checkbox>
                <Checkbox value="Material Order/Delivery">Material Order/Delivery</Checkbox>
                <Checkbox value="Other">Other</Checkbox>
              </Checkbox.Group>
              {errors.services && touched.services && <div className="error">{errors.services}</div>}
            </div>

            {/* Zip Code Input */}
            <div className='form-group'>
              <label>Zip Code</label>
              <Field
                type="text"
                name="zip_code"
                as={Input}
                className={errors.zip_code && touched.zip_code ? 'input-error' : ''}
              />
              {errors.zip_code && touched.zip_code && <div className="error">{errors.zip_code}</div>}
            </div>

            <div className='form-group'>
              <label>Message</label>
              <Field
                as="textarea"
                name="message"
                className={`${errors.message && touched.message ? 'input-error' : ''} w-100`}
              />
              {errors.message && touched.message && <div className="error">{errors.message}</div>}
            </div>

            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValid || !dirty} // Disable button if form is invalid or untouched
            >
              Send
            </Button>
          </Form>
        )}
      </Formik>

      {/* Success Modal */}
      <Modal
        title={null}
        open={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={null} // Removes the default footer with OK/Cancel buttons
        className="ant-modal-centered"
      >
        <div className="row">
          <div className="col-12">
            <img className="success-image" alt="Success Image" src="/check-mark-box-icon.png" />
            <h2>Thank You!</h2>
            <p className="success-text">Your estimate request has been successfully submitted. We'll get back to you soon!</p>
            {businessInfo?.social_media_1.link?.url && (
              <>
                <p className="social-media-text">
                  <a href={businessInfo.social_media_1.link.url} target="_blank" rel="noopener noreferrer">
                    Check us out on Facebook!
                  </a>
                </p>
                <a
                  className="social-media-icon"
                  href={businessInfo.social_media_1.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={businessInfo.social_media_1.link.title}></i>
                </a>
              </>
            )}
          </div>
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        title="Error"
        open={isError}
        onOk={() => setIsError(false)}
        onCancel={() => setIsError(false)}
        footer={null}
      >
        <p>There was an issue submitting your form. Please try again later.</p>
      </Modal>
    </>
  );
};

export default FreeEstimateForm;
