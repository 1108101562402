import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import Hero from '../components/Hero';
import '../styles/pages/ReviewUs.scss';
import useFetch from '../hooks/useFetch';
import { fetchPageContent } from '../utils/api';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import FooterHero from '../components/FooterHero';
import Testimonials from '../components/Testimonials';
import ProjectsSlider from '../components/ProjectsSlider';
import { NavLink } from 'react-router-dom';
import ContactForm from '../components/ContactForm';

const ReviewUs = () => {
  const { data: heroContent, data: interiorPageContent, loading } = useFetch(
    () => fetchPageContent('reviewus'),
    'reviewus'
  );
  const [title] = useState('Review Us');
  const [selected, setSelected] = useState(0);
  const [activeKey, setActiveKey] = useState('1');
  const [hover, setHover] = useState(0); // State for hover effect

  const handleRatingChange = (newRating) => {
    setSelected(newRating);
    if (newRating >= 4) {
      window.open(
        'https://www.google.com/search?q=Lizotte+Construction&sca_esv=a0afefd7843ec9f3&sxsrf=ADLYWILFyWTz8SwKxaera1StRYZUuDSqng%3A1733620030945&ei=PvFUZ-qlOebu5NoPz6XU8AY&ved=0ahUKEwjqvPrw_ZaKAxVmN1kFHc8SFW4Q4dUDCA8&uact=5&oq=Lizotte+Construction&gs_lp=Egxnd3Mtd2l6LXNlcnAiFExpem90dGUgQ29uc3RydWN0aW9uMgoQIxiABBgnGIoFMgQQIxgnMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESKk-UOcaWMQ1cAF4AJABAJgBogGgAcYFqgEDMy40uAEDyAEA-AEBmAIHoAL3BMICChAjGLACGLADGCfCAg4QABiABBiwAxiGAxiKBcICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgcQIxiwAhgnwgIIEAAYogQYiQWYAwDiAwUSATEgQIgGAZAGB5IHAzQuM6AHzy0&sclient=gws-wiz-serp#lrd=0x4cb1f927f37c9fe9:0x119f1cf60eaad983,3,,,,,,,,',
        '_blank'
      );
      // Keep the smiley face for 4 or 5 stars
      setActiveKey('1');
    } else {
      // Show the frown face for ratings 1, 2, or 3
      setActiveKey('2');
    }
  };

  const handleMouseEnter = (index) => {
    setHover(index + 1);
  };

  const handleMouseLeave = () => {
    setHover(selected);
  };

  return (
    <div>
      {/* Remove the skeleton and replace it with a simpler loading state */}
      {loading ? (
        <p style={{ textAlign: 'center', marginTop: '2rem' }}>Loading...</p>
      ) : (
        <>
          <Helmet>
            <title>Review Us Page</title>
            <meta
              name="description"
              content="This is the home page of the website."
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="canonical" href="http://yourwebsite.com/home" />

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            <link
              href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
              rel="stylesheet"
            />
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
              integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
              crossOrigin="anonymous"
            ></link>
            <script
              src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
              integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
              crossOrigin="anonymous"
            ></script>
          </Helmet>

          {/* Hero Content */}
          {heroContent && (
            <Hero
              className="interior-hero"
              content={heroContent.hero}
              featuredMedia={heroContent.featuredMedia}
            />
          )}

          {/* Main Content */}
          <div className="review-us-container container text-center mb-5">
            <div className="main-page-content row justify-content-center">
              <h1 className="mb-0">
                Click a star below and leave a Google review!
              </h1>
              <p>
                Or, feel free to{' '}
                <NavLink className="d-inline-block" to="/contact/">
                  Contact Us
                </NavLink>{' '}
                for any feedack, questions and or concerns!
              </p>

              <Nav
                variant="tabs"
                activeKey={activeKey}
                onSelect={(selectedKey) => setActiveKey(selectedKey)}
              >
                {activeKey === '1' && (
                  <Nav.Item>
                    <Nav.Link eventKey="1" title="Happy">
                      <span
                        className="d-inline-block"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            'https://www.google.com/search?q=Lizotte+Construction&sca_esv=a0afefd7843ec9f3&sxsrf=ADLYWILFyWTz8SwKxaera1StRYZUuDSqng%3A1733620030945&ei=PvFUZ-qlOebu5NoPz6XU8AY&ved=0ahUKEwjqvPrw_ZaKAxVmN1kFHc8SFW4Q4dUDCA8&uact=5&oq=Lizotte+Construction&gs_lp=Egxnd3Mtd2l6LXNlcnAiFExpem90dGUgQ29uc3RydWN0aW9uMgoQIxiABBgnGIoFMgQQIxgnMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESKk-UOcaWMQ1cAF4AJABAJgBogGgAcYFqgEDMy40uAEDyAEA-AEBmAIHoAL3BMICChAjGLACGLADGCfCAg4QABiABBiwAxiGAxiKBcICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgcQIxiwAhgnwgIIEAAYogQYiQWYAwDiAwUSATEgQIgGAZAGB5IHAzQuM6AHzy0&sclient=gws-wiz-serp#lrd=0x4cb1f927f37c9fe9:0x119f1cf60eaad983,3,,,,',
                            '_blank'
                          );
                        }}
                      >
                        <img
                          className="smiley-satisfied"
                          src="/satisfied-customer-icon 1 (1).webp"
                          alt="Satisfied Customer Icon"
                        />
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                )}
                {activeKey === '2' && (
                  <Nav.Item>
                    <Nav.Link eventKey="2" title="Unhappy">
                      <span
                        className="d-inline-block"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            'https://www.google.com/search?q=Lizotte+Construction&sca_esv=a0afefd7843ec9f3&sxsrf=ADLYWILFyWTz8SwKxaera1StRYZUuDSqng%3A1733620030945&ei=PvFUZ-qlOebu5NoPz6XU8AY&ved=0ahUKEwjqvPrw_ZaKAxVmN1kFHc8SFW4Q4dUDCA8&uact=5&oq=Lizotte+Construction&gs_lp=Egxnd3Mtd2l6LXNlcnAiFExpem90dGUgQ29uc3RydWN0aW9uMgoQIxiABBgnGIoFMgQQIxgnMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESKk-UOcaWMQ1cAF4AJABAJgBogGgAcYFqgEDMy40uAEDyAEA-AEBmAIHoAL3BMICChAjGLACGLADGCfCAg4QABiABBiwAxiGAxiKBcICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgcQIxiwAhgnwgIIEAAYogQYiQWYAwDiAwUSATEgQIgGAZAGB5IHAzQuM6AHzy0&sclient=gws-wiz-serp#lrd=0x4cb1f927f37c9fe9:0x119f1cf60eaad983,3,,,,',
                            '_blank'
                          );
                        }}
                      >
                        <img
                          className="smiley-satisfied"
                          src="/satisfied-customer-icon 1 (1).webp"
                          alt="Satisfied Customer Icon"
                        />
                      </span>
                      <img
                        className="smiley-dissatisfied"
                        src="/dissatisfied-customer-icon.webp"
                        alt="Dissatisfied Customer Icon"
                      />
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>

              <div>
                {activeKey === '1' && (
                  <>
                    <div className="custom-rating">
                      {Array.from({ length: 5 }, (_, index) => (
                        <i
                          key={index}
                          className={
                            index < (hover || selected) ? 'fas fa-star' : 'far fa-star'
                          }
                          style={{ color: '#FFD700', cursor: 'pointer' }}
                          onClick={() => handleRatingChange(index + 1)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        />
                      ))}
                    </div>
                  </>
                )}
                {activeKey === '2' && (
                  <>
                    <div className="custom-rating mb-4">
                      {Array.from({ length: 5 }, (_, index) => (
                        <i
                          key={index}
                          className={
                            index < (hover || selected) ? 'fas fa-star' : 'far fa-star'
                          }
                          style={{ color: '#FFD700', cursor: 'pointer' }}
                          onClick={() => handleRatingChange(index + 1)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        />
                      ))}
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-6">
                        <strong className="mb-3 d-block">
                          Use the form for any comments and feedback!
                        </strong>
                        <div className="form-card text-start">
                          <ContactForm />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Testimonials />
      <ProjectsSlider />
      <FooterHero />
      <Footer />
    </div>
  );
};

export default ReviewUs;
