// // src/pages/Services.js

// import React, { useState } from 'react';
// import Hero from '../components/Hero';
// import { fetchPageContent } from '../utils/api';
// import useFetch from '../hooks/useFetch';
// import { Helmet } from 'react-helmet-async';
// import InteriorHeroSkeleton from '../components/InteriorHeroSkeleton';
// import FooterHero from '../components/FooterHero';
// import Footer from '../components/Footer';
// import axios from 'axios';
// import Testimonials from '../components/Testimonials';

// const Contact = () => {
//   const { data: heroContent, loading, data: interiorPageContent } = useFetch(() => fetchPageContent('contact'), 'contact');

//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     message: ''
//   });
//   const [status, setStatus] = useState('');

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus('Sending...');

//     try {
//       const response = await axios.post('http://localhost:5001/api/email', formData);
//       setStatus(response.data.message);
//       setFormData({ name: '', email: '', message: '' }); // Clear the form
//     } catch (error) {
//       setStatus('Failed to send email. Please try again later.');
//       console.error(error);
//     }
//   };


//   return (
//     <div>
//       {loading ? (
//         // Render HeroSkeleton component when loading
//         <InteriorHeroSkeleton />
//       ) : (
//         (
//           <>
//             <Helmet>
//               <title>Home Page</title>
//               <meta name="description" content="This is the home page of the website." />
//               <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//               <link rel="canonical" href="http://yourwebsite.com/home" />

//               <link rel="preconnect" href="https://fonts.googleapis.com" />
//               <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
//               <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
//               <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"></link>
//               <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
//             </Helmet>
//             {loading ? (
//               <p>Loading...</p>
//             ) : (
//               heroContent && <Hero className="interior-hero" content={heroContent.hero} featuredMedia={heroContent.featuredMedia} />
//             )}
//             <div className="container py-5">

//               {/* Other Services Page Content */}
//               {interiorPageContent?.interiorPageContent && (
//                 <div className="row justify-content-center">
//                   <div className='col-wd-7 col-lg-8' dangerouslySetInnerHTML={{ __html: interiorPageContent.interiorPageContent }} />
//                 </div>
//               )}

//               <form onSubmit={handleSubmit}>
//                 <div>
//                   <label htmlFor="name">Name</label>
//                   <input type="text" name="name" value={formData.name} onChange={handleChange} required />
//                 </div>
//                 <div>
//                   <label htmlFor="email">Email</label>
//                   <input type="email" name="email" value={formData.email} onChange={handleChange} required />
//                 </div>
//                 <div>
//                   <label htmlFor="message">Message</label>
//                   <textarea name="message" value={formData.message} onChange={handleChange} required />
//                 </div>
//                 <button type="submit">Send</button>
//               </form>
//               {status && <p>{status}</p>}

//             </div>
//           </>
//         )
//       )}
//       <Testimonials />
//       <FooterHero />
//       <Footer />
//     </div>
//   );
// };

// export default Contact;


// src/pages/WhoWeAre.js

import React, { useContext } from 'react';
import Hero from '../components/Hero';
import { fetchPageContent } from '../utils/api';
import useFetch from '../hooks/useFetch';
import { Helmet } from 'react-helmet-async';
import InteriorHeroSkeleton from '../components/InteriorHeroSkeleton';
import Footer from '../components/Footer';
import FooterHero from '../components/FooterHero';
import Testimonials from '../components/Testimonials';
import '../styles/components/WordPressBlocks.scss';
import ContactForm from '../components/ContactForm';
import '../styles/components/FreeEstimateFormScss.scss';
import ProjectsSlider from '../components/ProjectsSlider';
import { GlobalDataContext } from '../context/GlobalDataContext';
import { NavLink } from 'react-router-dom';

const Contact = () => {
  const { data: heroContent, data: interiorPageContent, loading } = useFetch(() => fetchPageContent('contact'), 'contact');
  const { businessInfo } = useContext(GlobalDataContext);

  return (
    <div>
      {loading ? (
        // Render HeroSkeleton component when loading
        <InteriorHeroSkeleton />
      ) : (
        (
          <>
            <Helmet>
              <title>Contact Us - Lizotte Construction</title>
              <meta name="description" content="We have 25+ years of experience, are dedicated and devoted to each and every one of our clients. Specializing in gravel, septic systems, house lot development, and trucking, Lizotte Construction delivers reliable, high-quality service." />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <link rel="canonical" href="http://yourwebsite.com/home" />

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
              <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"></link>
              <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
            </Helmet>
            {loading ? (
              <p>Loading...</p>
            ) : (
              heroContent && <Hero className="interior-hero" content={heroContent.hero} featuredMedia={heroContent.featuredMedia} />
            )}
            {/* Other WhoWeAre Page Content */}
            {interiorPageContent?.interiorPageContent && (
              <div className="container">
                <div className="row justify-content-center panel pb-0">
                  <div className='col-12 mb-4' dangerouslySetInnerHTML={{ __html: interiorPageContent.interiorPageContent }} />

                  <div className='col-12'>
                    <div className="row">
                      <div className='col-lg-7'>
                        <div className='form-card'>
                          <ContactForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            )}
            <div className="container">
              <div className="row justify-content-center panel pb-0">
                <div className='col-12 mb-4' dangerouslySetInnerHTML={{ __html: interiorPageContent.interiorPageContent }} />

                <div className='col-12'>
                  <div className="row">
                    <div className='col-lg-7'>
                      <div className='form-card'>
                        <ContactForm />
                      </div>
                    </div>
                    <div className='col-lg-5 col-form-info'>
                      <div className='form-info-card'>
                        {businessInfo && businessInfo.logo && (
                          <div
                            className="logo-container"
                          >
                            <NavLink to="/">
                              <img className="logo" src={businessInfo.logo} alt="Business Logo" />
                            </NavLink>
                          </div>
                        )}

                        <div className="contact-hours">
                          {businessInfo && businessInfo.phone && (
                            <div>
                              <NavLink className="d-flex align-items-center gap-2 mt-3" to={`tel:${businessInfo.phone}`}>
                                <i className="fa-solid fa-phone"></i>
                                <span>{businessInfo.phone}</span>
                              </NavLink>
                            </div>
                          )}
                          {businessInfo && businessInfo.cell_phone && (
                            <div>
                              <NavLink className="d-flex align-items-center gap-2 mt-2" to={`tel:${businessInfo.cell_phone}`}>
                                <i className="fa-solid fa-phone"></i>
                                <span>{businessInfo.cell_phone}</span>
                              </NavLink>
                            </div>
                          )}

                          {businessInfo && businessInfo.hours && (
                            <span className='hours'
                              dangerouslySetInnerHTML={{ __html: businessInfo.hours }} />
                          )}
                          {businessInfo?.social_media_1.link?.url && (
                            <a href={businessInfo.social_media_1.link.url} target='_blank' rel="noopener noreferrer">
                              <i className={businessInfo.social_media_1.link.title}></i>
                            </a>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12 mt-5'>
                  <div className="interior-page-section">
                    <div className="section-background">
                      {/* style={{ backgroundImage: `url(http://headless-lizotteconstruction.checkmatewebdesign.com/wp-content/uploads/2024/11/20221006_152132-scaled.webp)` }} */}
                      {businessInfo && businessInfo.map && (
                        <iframe src={businessInfo.map} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                      )}
                    </div>
                    <div className="container">
                      <h2>Proudly Serving Central and Southern Maine</h2>
                      <p>Lizotte Construction proudly serves central and southern Maine, providing high-quality construction and renovation services tailored to meet the unique needs of each client. Our service area spans several communities, ensuring that homeowners and businesses have access to reliable and professional craftsmanship.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
      <ProjectsSlider />
      <Testimonials />
      <FooterHero />
      <Footer />
    </div >
  );
};

export default Contact;
