// src/utils/api.js
const WORDPRESS_API_BASE = process.env.REACT_APP_WORDPRESS_API_BASE;
const WORDPRESS_MEDIA_BASE = process.env.REACT_APP_WORDPRESS_MEDIA_BASE;
const WORDPRESS_PROJECTS_BASE = process.env.REACT_APP_WORDPRESS_PROJECTS_BASE;

export const fetchPageContent = async (slug) => {
  try {
    const response = await fetch(`${WORDPRESS_API_BASE}?slug=${slug}`);
    const data = await response.json();
    // console.log("Using API base URL:", WORDPRESS_API_BASE);
    // console.log("Environment mode:", process.env.NODE_ENV);
    // console.log("Raw API base URL:", process.env.REACT_APP_WORDPRESS_API_BASE);


    // console.log(data); // Inspect the returned data structure
    const pageData = data[0]; // Get the first item since we expect a single page response

    const heroContent = pageData?.acf?.hero || null;
    const featuredMediaId = pageData?.featured_media;
    const servicesImageId = pageData?.service_image;
    const interiorPageContent = pageData?.content?.rendered || null; // Add content here


    // Fetch featured media if it exists
    let featuredMedia = null;
    if (featuredMediaId) {
      const mediaResponse = await fetch(`${WORDPRESS_MEDIA_BASE}/${featuredMediaId}`);
      featuredMedia = await mediaResponse.json();
    }

    let serviceImage = null;
    if (servicesImageId) {
      const serviceImageResponse = await fetch(`${WORDPRESS_MEDIA_BASE}/${servicesImageId}`);
      serviceImage = await serviceImageResponse.json();
    }

    // Extract additional ACF fields
    const whyChoose = pageData?.acf?.why_choose || null;
    const homeServices = pageData?.acf?.home_services || null;
    const homeProjectsCarousel = pageData?.acf?.home_projects_carousel || null;

    return {
      hero: heroContent,
      featuredMedia,
      whyChoose,
      serviceImage,
      homeServices,
      homeProjectsCarousel,
      interiorPageContent,
    };
  } catch (error) {
    console.error("Error fetching page content:", error);
    return null;
  }
};




// src/utils/api.js

export const fetchProjects = async () => {
  try {
    const response = await fetch(WORDPRESS_PROJECTS_BASE);
    const projects = await response.json();

    // Map over each project to fetch its featured media
    const projectsWithMedia = await Promise.all(
      projects.map(async (project) => {
        const featuredMediaId = project.featured_media;

        let featuredMedia = null;
        if (featuredMediaId) {
          const mediaResponse = await fetch(`${WORDPRESS_MEDIA_BASE}/${featuredMediaId}`);
          featuredMedia = await mediaResponse.json();
        }

        return {
          id: project.id,
          title: project.title.rendered,
          content: project.content.rendered,
          acfFields: project.acf,
          featuredMedia, // Correctly associate the featured media
        };
      })
    );

    return projectsWithMedia;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return [];
  }
};





export const fetchGlobalData = async () => {
  try {
    const response = await fetch(`${WORDPRESS_API_BASE}/70`); // Fetch page with ID 70
    const data = await response.json();

    // Extract the business_info ACF field
    const businessInfo = data.acf.business_info || null;
    const footerHero = data.acf.footer_hero || null;
    const testimonials = data.acf.testimonials || null;

    // Fetch logo media if logo ID exists
    let logoUrl = null;
    if (businessInfo && businessInfo.logo) {
      const logoResponse = await fetch(`${WORDPRESS_MEDIA_BASE}/${businessInfo.logo}`);
      const logoData = await logoResponse.json();
      logoUrl = logoData.source_url; // Get the image URL from the media object
    }

    // Fetch logo media if logo ID exists
    let logoFooterUrl = null;
    if (businessInfo && businessInfo.logo_footer) {
      const logoFooterResponse = await fetch(`${WORDPRESS_MEDIA_BASE}/${businessInfo.logo_footer}`);
      const logoFooterData = await logoFooterResponse.json();
      logoFooterUrl = logoFooterData.source_url; // Get the image URL from the media object
    }

    return {
      businessInfo: {
        ...businessInfo,
        logo: logoUrl, // Replace the logo ID with the actual URL
        logoFooter: logoFooterUrl, // Replace the logo ID with the actual URL
      },
      footerHero,
      testimonials
    };
  } catch (error) {
    console.error("Error fetching global data:", error);
    return null;
  }
};
