import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import Gravel from './pages/Services/Gravel';
import WhoWeAre from './pages/WhoWeAre';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Estimate from './pages/Estimate';
import Header from './components/Header';
import { GlobalDataProvider } from './context/GlobalDataContext';
import { HelmetProvider } from 'react-helmet-async';
import './styles/Variables.scss';
import './styles/Mixins.scss';
import './styles/Global.scss';
import initAOS from './utils/AOSInit'; // Import the AOS initialization function
import ScrollToTop from './components/ScrollTop';
import { HeaderProvider } from './context/HeaderContext';
import SepticSystems from './pages/Services/SepticSystems';
import NotFound from './components/NotFound';
import MaintenanceAndMore from './pages/Services/MaintenanceAndMore';
import HouseLots from './pages/Services/HouseLots';
import ReviewUs from './pages/ReviewUs';
import AlertModal from './components/AlertModal';
import GarageSlabs from './pages/Services/GarageSlabs';


function App() {
  useEffect(() => {
    initAOS(
      {
        once: true, // This will make animations run only once
      }
    ); // Initialize AOS when the App component mounts
  }, []);

  return (
    <HelmetProvider>
      <HeaderProvider>
        <GlobalDataProvider>
          <Router>
            <ScrollToTop />
            <AlertModal />
            <Header />
            <div className='site-container'>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/gravel" element={<Gravel />} />
                <Route path="/services/septic-systems" element={<SepticSystems />} />
                <Route path="/services/maintenance-and-more" element={<MaintenanceAndMore />} />
                <Route path="/services/house-lots" element={<HouseLots />} />
                <Route path="/services/garage-slabs" element={<GarageSlabs />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/whoweare" element={<WhoWeAre />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/estimate" element={<Estimate />} />
                <Route path="/reviewus" element={<ReviewUs />} />
                {/* Catch-all 404 Route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Router>
        </GlobalDataProvider>
      </HeaderProvider>
    </HelmetProvider>
  );
}

export default App;
