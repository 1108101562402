// src/hooks/useFetch.js
import { useState, useEffect } from 'react';

const useFetch = (fetchFunction, dependency) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await fetchFunction();
      setData(result);
      setLoading(false);
    };

    fetchData();
  }, [dependency]);

  return { data, loading };
};

export default useFetch;
