// src/components/HeroSkeleton.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/components/Hero.scss';

const HeroSkeleton = () => (
  <div className="hero-skeleton">
    <div className="container">
      <Skeleton className="skeleton-image mb-5" height={20} width="20%" />

      <Skeleton className="skeleton-text" height={60} width="40%" />
      <Skeleton className="skeleton-text" height={60} width="60%" />
      <Skeleton className="skeleton-text" height={60} width="50%" />
      <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
        <Skeleton className="skeleton-button" height={20} width={100} />
        <Skeleton className="skeleton-button" height={20} width={100} />
      </div>
    </div>
  </div>
);

export default HeroSkeleton;
