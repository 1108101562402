import React, { useEffect, useRef } from "react";
import "../styles/components/BeforeAfterSliderComponent.scss"; // Include your CSS here

const BeforeAfterSlider = () => {
  const sliderRef = useRef(null);
  const beforeRef = useRef(null);
  const handleRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const before = beforeRef.current;
    const handle = handleRef.current;

    const setInitialWidths = () => {
      const currentWidth = before.offsetWidth; // Get current width of top image
      const newTopWidth = (currentWidth * 2) / 3; // Calculate new top width (2/3 of current width)
      before.style.width = `${newTopWidth}px`; // Set the top image with the new width
      handle.style.left = `${newTopWidth}px`; // Set the left margin for the handle
    };

    const slideBar = (event) => {
      const newWidth = parseInt(handle.style.left, 10); // Get new width based on handle position
      before.style.width = `${newWidth}px`; // Adjust the width of the "before" image
    };

    const onMouseDown = () => {
      // Optional tooltip fade out (if needed)
    };

    const onDrag = (event) => {
      const rect = slider.getBoundingClientRect();
      const newLeft = Math.min(
        Math.max(0, event.clientX - rect.left),
        rect.width
      );
      handle.style.left = `${newLeft}px`;
      slideBar(event);
    };

    handle.addEventListener("mousedown", () => {
      document.addEventListener("mousemove", onDrag);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", onDrag);
      });
    });

    // Set initial positions on mount
    setInitialWidths();

    return () => {
      handle.removeEventListener("mousedown", onDrag);
    };
  }, []);

  return (
    <div className="slider-mask">
      <div ref={sliderRef} id="container" className="AB-slidebar">
        <div ref={beforeRef} className="slider-bef">
          <div
            className="background-image-bef"
            style={{

              height: "550px",
              background: "blue",
              backgroundImage: "url('http://headless-lizotteconstruction.checkmatewebdesign.com/wp-content/uploads/2024/12/IMG_3202-1.png')",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
        <div className="slider-aft">
          <div
            className="background-image-aft"
            style={{

              height: "550px",
              background: "red",
              backgroundImage: "url('http://headless-lizotteconstruction.checkmatewebdesign.com/wp-content/uploads/2024/12/IMG_3166-1.png')",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
        <div ref={handleRef} className="handle-wrapper">
          <div className="slider-bar">
            <img
              src="https://i.imgur.com/ylG0seD.png"
              alt="Handle"
              className="slider-handle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeAfterSlider;
