import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { GlobalDataContext } from '../context/GlobalDataContext';

const AlertModal = () => {
  const { businessInfo } = useContext(GlobalDataContext);
  // Check if the cookie is set to prevent showing the modal
  const isCookieSet = Cookies.get('alertModalDismissed');
  const [isVisible, setIsVisible] = useState(!isCookieSet);

  // Function to handle the dismiss action
  const dismissModal = () => {
    // Set the cookie with a 1-day expiration if businessInfo.alert.cookie is true
    if (businessInfo?.alert?.cookie) {
      Cookies.set('alertModalDismissed', 'true', { expires: 1 });
    }
    setIsVisible(false);
  };

  useEffect(() => {
    // If businessInfo.alert.cookie is true, set the cookie
    if (businessInfo?.alert?.cookie && !isCookieSet) {
      Cookies.set('alertModalDismissed', 'true', { expires: 1 });
    }
  }, [businessInfo?.alert?.cookie, isCookieSet]);

  if (!isVisible) {
    return null; // Don't render anything if the modal is dismissed
  }

  return (
    <>
      {businessInfo && businessInfo.alert.toggle === true && (
        <div className='alert-modal' style={styles.modal}>
          <div className='alert-modal-content' style={styles.modalContent}>
            {businessInfo && businessInfo.alert.content && (
              <div
                className='mb-3'
                dangerouslySetInnerHTML={{ __html: businessInfo.alert.content }} />
            )}
            <button className='button alert-btn' onClick={dismissModal}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  modalContent: {
    background: 'white',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    minWidth: '300px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  }
};

export default AlertModal;
