import React, { useContext, useState } from 'react';
import { HeaderContext } from '../context/HeaderContext';
import { NavLink } from 'react-router-dom';
import '../styles/components/Sidebar.scss';

function Sidebar() {
  const { isHeaderVisible } = useContext(HeaderContext);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for toggling sidebar

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

  const links = [
    { path: '/services', label: 'All Services' },
    { path: '/services/gravel', label: 'Gravel' },
    { path: '/services/septic-systems', label: 'Septic Systems' },
    { path: '/services/house-lots', label: 'House Lots' },
    { path: '/services/maintenance-and-more', label: 'Maintenance and More' },
    { path: '/services/garage-slabs', label: 'Garage Slabs' },
  ];

  const maxTextLength = 12;

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState); // Toggle sidebar state
  };

  return (
    <div className="col-sidebar col-3">
      <div className={`sidebar ${isHeaderVisible ? '' : 'header-hidden'}`}>
        <h2 className="d-none nav-title">Services</h2>

        {/* Hamburger Icon */}
        <div id="nav-icon2" className={`sidebar-nav ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`sidebar-nav ${isSidebarOpen ? 'open' : ''}`}>
          {links.map((link) => (
            <li key={link.path} className="sidebar-item">
              <NavLink to={link.path} className={link.path === '/services' ? 'no-active-effect' : ''}>
                {link.path === '/services' ? <i className="fa-solid fa-gear"></i> : ''}
                {link.path === '/services/gravel' && <img src='../charcoal-icon.webp' />}
                {link.path === '/services/septic-systems' && <i className="fa fa-tint"></i>}
                {link.path === '/services/house-lots' && <i className="fa fa-home"></i>}
                {link.path === '/services/maintenance-and-more' && <i className="fa fa-wrench"></i>}
                {link.path === '/services/garage-slabs' && <i class="fa-solid fa-warehouse"></i>}
                <span>{truncateText(link.label, maxTextLength)}</span>
              </NavLink>
              {link.label.length > maxTextLength && (
                <span className="tooltip-icon"
                  onMouseEnter={() => setHoveredLink(link.label)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  <i className="fa fa-info-circle"></i>
                  {hoveredLink === link.label && (
                    <div className="custom-tooltip">
                      <NavLink to={link.path}>{link.label}</NavLink>
                    </div>
                  )}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
