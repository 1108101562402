import React, { useContext, useState, useEffect } from 'react';
import { GlobalDataContext } from '../context/GlobalDataContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectFade } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import '../styles/components/testimonials.scss';
import '../styles/Variables.scss';
import '../styles/Mixins.scss';

function Testimonials() {
  const { testimonials } = useContext(GlobalDataContext);
  const [expandedQuotes, setExpandedQuotes] = useState({});  // Keep track of which quotes are expanded
  const [swiperRef, setSwiperRef] = useState(null);

  const MAX_LENGTH = 380;

  // No need to reset expandedQuotes when the slide changes now.
  useEffect(() => {
    if (swiperRef) {
      const handleSlideChange = () => {
        // You can choose to do something on slide change if needed.
      };

      swiperRef.on('slideChange', handleSlideChange);
      return () => swiperRef.off('slideChange', handleSlideChange); // Clean up event listener
    }
  }, [swiperRef]);

  // Toggle the expanded state for individual quotes
  const toggleQuote = (key) => {
    setExpandedQuotes((prevState) => ({
      ...prevState,
      [key]: !prevState[key],  // Toggle the specific quote's expanded state
    }));
  };

  if (!testimonials) {
    return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-7'></div>
        </div>
      </div>
    );
  }

  return (
    <div className="testimonials-section">
      <div className="container panel testimonials">
        <div className="row">
          <div className="col-lg-5 col-testimonial-heading"
            data-aos="slide-up"
            data-aos-duration="300"
          >
            <i className="fa-solid fa-quote-left"></i>
            {testimonials.title && (
              <h2 className="line-1">
                {testimonials.title}
                <NavLink className="review-us-btn button" to="/reviewus">
                  Leave a Review
                </NavLink>
              </h2>
            )}
          </div>
          <div className="col-lg-7" data-aos="slide-up" data-aos-duration="300">
            <Swiper
              onSwiper={setSwiperRef}
              modules={[Navigation, Autoplay, EffectFade]}
              spaceBetween={50}
              slidesPerView={1}
              autoHeight={false}
              loop={true}
              speed={600}
              navigation={{
                nextEl: '.testimonnial-swiper-button-next',
                prevEl: '.testimonnial-swiper-button-prev',
              }}
              className="testimonial-swiper"
            >
              {Object.keys(testimonials)
                .filter((key) => key.startsWith("testimonial_"))
                .sort((a, b) => a.localeCompare(b))
                .map((key) => {
                  const testimonial = testimonials[key];
                  if (testimonial && testimonial.quote && testimonial.name) {
                    const isLongQuote = testimonial.quote.length > MAX_LENGTH;
                    return (
                      <SwiperSlide key={key}>
                        <div className="feature">
                          <p>
                            {isLongQuote && !expandedQuotes[key]
                              ? `${testimonial.quote.slice(0, MAX_LENGTH)}...`
                              : testimonial.quote}
                            {isLongQuote && (
                              <span
                                className="read-more d-inline-block"
                                onClick={() => toggleQuote(key)}
                              >
                                {expandedQuotes[key] ? 'Show Less' : 'Read More'}
                              </span>
                            )}
                          </p>
                          <h2 className="testimonial-name">{testimonial.name}</h2>
                        </div>
                      </SwiperSlide>
                    );
                  }
                  return null;
                })}
            </Swiper>
            <div className="testimonials-arrow-slider">
              <div className="testimonnial-swiper-button-prev">
                <i className="fa-solid fa-circle-arrow-left"></i>
              </div>
              <div className="testimonnial-swiper-button-next">
                <i className="fa-solid fa-circle-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
