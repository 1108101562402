// src/pages/WhoWeAre.js

import React from 'react';
import Hero from '../components/Hero';
import { fetchPageContent } from '../utils/api';
import useFetch from '../hooks/useFetch';
import { Helmet } from 'react-helmet-async';
import InteriorHeroSkeleton from '../components/InteriorHeroSkeleton';
import Footer from '../components/Footer';
import FooterHero from '../components/FooterHero';
import Testimonials from '../components/Testimonials';
import ProjectsSlider from '../components/ProjectsSlider';

const WhoWeAre = () => {
  const { data: heroContent, data: interiorPageContent, loading } = useFetch(() => fetchPageContent('whoweare'), 'whoweare');

  return (
    <div>
      {loading ? (
        // Render HeroSkeleton component when loading
        <InteriorHeroSkeleton />
      ) : (
        (
          <>
            <Helmet>
              <title>Home Page</title>
              <meta name="description" content="This is the home page of the website." />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <link rel="canonical" href="http://yourwebsite.com/home" />

              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
              <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"></link>
              <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
            </Helmet>
            {loading ? (
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-12'>
                    <p>Loading...</p>
                  </div>
                </div>
              </div>
            ) : (
              heroContent && <Hero className="interior-hero" content={heroContent.hero} featuredMedia={heroContent.featuredMedia} />
            )}
            {/* Other WhoWeAre Page Content */}
            {interiorPageContent?.interiorPageContent && (
              <div className='container'>
                <div className="main-page-content row justify-content-center panel">
                  <div className='col-lg-12'>
                    <div className='page-card' dangerouslySetInnerHTML={{ __html: interiorPageContent.interiorPageContent }} />
                  </div>
                </div>
              </div>
            )}
          </>
        )
      )}
      <ProjectsSlider />
      <Testimonials />
      <FooterHero />
      <Footer />
    </div>
  );
};

export default WhoWeAre;