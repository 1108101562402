import React from 'react';
import { Link } from 'react-router-dom';
import ProjectsSlider from './ProjectsSlider';
import Testimonials from './Testimonials';
import FooterHero from './FooterHero';
import Footer from './Footer';

const NotFound = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="not-found">
              <h1>Oops! Page Not Found</h1>
              <p>The page you're looking for doesn't exist or has been moved.</p>
              <Link to="/" className="button mx-auto">Go to Homepage</Link>
            </div>
          </div>
        </div>
      </div>
      <ProjectsSlider />
      <Testimonials />
      <FooterHero />
      <Footer />
    </>
  );
};

export default NotFound;
